<template>
  <div id="chat_list">
    <div class="chat_list_main">
      <div class="entrance">
        <div class="entrance_title">
          <span></span>
          <img src="@/assets/image/PersonalCenter/ChatList/joinRoom.png" alt="">
          <div>加入聊天室</div>
        </div>
        <div class="entrance_join">
          <div class="entrance_input">
            <el-input v-model="invitationCode" placeholder="请输入邀请码" :maxlength="6"></el-input>
          </div>
          <div class="entrance_btn" @click="roomJoin">
            确定加入
          </div>
        </div>
      </div>
      <div class="chat_title">
        <span></span>
        <img src="@/assets/image/PersonalCenter/ChatList/chatListItem.png" alt="">
        <div>消息列表</div>
      </div>
      <el-tabs class="chat-tabs" v-model="activeName" @tab-click="getStationList">
        <el-tab-pane label="全部消息" name="0"></el-tab-pane>
        <el-tab-pane label="客服" name="2"></el-tab-pane>
        <el-tab-pane label="交易组" name="1"></el-tab-pane>
        <el-tab-pane label="交流群" name="3"></el-tab-pane>
      </el-tabs>
      <div class="chat_list" v-if="totalCount !== 0">
        <div :class="item.status === 0 ?'chat_list_item_unread':'chat_list_item'"
            v-for="(item,index) in tableData.slice((currentPage-1)*PageSize,currentPage*PageSize)"
            :key="index">
          <div class="item_img">
            <img v-if="item.avtar" :src="item.avtar" alt="">
            <img v-else src="@/assets/image/chat/active.jpg" alt="">
          </div>
          <div class="item_content">
            <div class="IC_name">
              <div class="IC_tag" :class="`IC_tag_${item.type}`">
                {{ item.type == 1 ? '交易组' : item.type == 2 ? '私聊' : '交流群' }}
              </div>
              <div id="IC_tagName">{{ item.name }}</div>
              <div class="item_num" v-show="item.type !== 2">（{{ item.num }}人）</div>
            </div>
            <div class="IC_msg">
              <span v-if="item.type != 2 && item.msg_identity !== '用户'">【{{ item.msg_identity }}】</span>
              <span v-if="item.type != 2">{{ item.msg_sender }}：</span>
              <div v-html="item.message"></div>
            </div>
          </div>
          <div class="item_date">
            <span v-if="item.msg_time !=0">
               {{ item.msg_time|moments }}
            </span>
          </div>
          <div class="item_state">
            <el-tag v-if="item.unread > 0 " type="danger" size="medium" effect="dark">+{{
                item.unread >= 100 ? 99 : item.unread
              }}
            </el-tag>
            <el-tag v-else type="info" size="medium">已读</el-tag>
          </div>
          <div class="item_btn" @click="enterRoom(item,index)">
            进入聊天
          </div>
        </div>
        <div class="pages" v-if="totalCount !== 0">
          <el-pagination
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage"
              :page-size="PageSize"
              layout="prev, pager, next, jumper"
              :total="totalCount">
          </el-pagination>
        </div>
        <div></div>
      </div>
      <div class="chat_list no_data" v-else>
        <div id="noData">
          <img src="@/assets/image/PersonalCenter/SiteNotice/noData.png" alt="">
        </div>
      </div>
    </div>
    <el-dialog
        :visible.sync="ICDialogVisible"
        custom-class="invitationCode"
        :show-close="false"
        width="495px"
        top="35vh"
        center>
          <span>
            <div id="IC_main">
              <div class="IC_title">输入邀请码</div>
              <div class="IC_input">
                <el-input v-model="invitationCode" placeholder="请输入邀请码" :maxlength="6"></el-input>
              </div>
              <div class="IC_btn" @click="roomJoin">
                进入聊天室
              </div>
            </div>
            <img class="IC_close" src="@/assets/image/AccountDeal/close.png" alt="" @click="ICDialogVisible = false">
          </span>
    </el-dialog>
  </div>
</template>
<script>
import {apiRoomList, apiRoomJoin, apiRoomEnter} from "@/request/API";

export default {
  name: '',
  props: {},
  components: {},
  data() {
    return {
      // 默认显示第几页
      currentPage: 1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount: 0,
      // 默认每页显示的条数（可修改）
      PageSize: 5,
      selectItem: '聊天室列表',
      tabbarItem: ['聊天室列表'],
      activeName: '0',
      tableData: [],
      //分页页码
      pages: 1,
      //输入邀请码弹窗
      ICDialogVisible: false,
      //邀请码
      invitationCode: ''

    }
  },
  methods: {

    //分页监听事件
    handleCurrentChange(val) {
      this.currentPage = val
    },

    //加入房间
    enterRoom(item, index) {
      let routeUrl = this.$router.resolve({
        name: 'chatRoom',
        path: '',
        query: {
          room_id: item.id
        }
      });
      window.open(routeUrl.href, '_blank');
    },
    /**
     * 获取 站内通知 列表
     * @param page 当前页码
     */
    getStationList(page) {
      apiRoomList({
        client: 'front',
        room_type: this.activeName,
      }).then(res => {
        this.totalCount = res.data.length
        this.tableData = res.data
      })
    },
    /**
     * 买卖双方加入房间
     */
    roomJoin() {
      apiRoomJoin({
        client: 'front',
        code: this.invitationCode,
      }).then(res => {
        let routeUrl = this.$router.resolve({
          name: 'chatRoom',
          query: {
            room_id: res.data.id,
          }
        });
        window.open(routeUrl.href, 'scrollbars=yes,resizable=1,modal=false,alwaysRaised=yes');
      }).catch(err => {
        this.$message({
          type: "error",
          center: true,
          message: err.msg
        })
      })
    },
    //websocket
    initSocket() {
      this.$WS.on('room_list', (e) => {
        this.activeName = '0'
        this.tableData = e.data
        this.totalCount = this.tableData.length
      })
    },
  },
  mounted() {
    //初始化数据
    this.getStationList(1)
    this.initSocket()
  },
  watch: {},
  computed: {}
}
</script>
<!-- 弹框 -->
<style lang="less">
.invitationCode {
  width: 495px;
  height: 290px;
  background: #FFFFFF;
  border-radius: 10px;
  position: relative;
  overflow: visible;

  .el-dialog__body {
    padding: 40px 53px 0;
    position: relative;

    #IC_main {
      display: flex;
      flex-direction: column;
      //align-items: center;
      position: relative;

      .IC_title {
        text-align: center;
        margin: 0 0 10px;
        font-size: 20px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 28px;
      }

      .IC_input {
        margin: 0 0 46px;
      }

      .IC_btn {
        text-align: center;
        font-size: 16px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 50px;
        height: 50px;
        background: #FFDB51;
        border-radius: 30px;
        cursor: pointer;
      }
    }

    .IC_close {
      position: absolute;
      bottom: -110px;
      left: 232px;
      cursor: pointer;
    }
  }

  .el-dialog__header {
    padding: 0;
  }

  .el-input__inner {
    height: 56px;
    background: #F4F4F4;
    border-radius: 4px;
    border: 1px solid #F0F0F0;
    font-size: 18px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 400;
    color: #000000;
  }
}

.IC_msg * {
  margin: 0;
}
</style>
<style lang='less' scoped>

#chat_list {
  padding: 0 19px;

  .chat_list_main {
    display: flex;
    flex-direction: column;

    .entrance {
      padding: 41px 0 43px 46px;
      border-bottom: 1px solid #e2e2e2;
      margin: 0 0 38px;

      .entrance_title {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 0 32px;

        span {
          display: inline-block;
          width: 3px;
          height: 12px;
          background: #000000;
          border-radius: 2px;
        }

        img {
          margin: 0 9px 0 16px;
          width: 28px;
          height: 24px;
        }

        div {
          font-size: 20px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: #000000;
          line-height: 20px;
        }
      }

      .entrance_join {
        display: flex;
        flex-direction: row;
        align-items: center;

        /deep/ .el-dialog__header {
          padding: 0;
        }

        /deep/ .el-input__inner {
          width: 367px;
          height: 50px;
          background: #f7f7f7;
          border-radius: 27px;
          border: 1px solid #F0F0F0;
          font-size: 18px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 400;
          color: #000000;
          padding: 0 37px;
        }

        .entrance_input {
          width: 367px;
          height: 50px;
          background: #f7f7f7;
          border-radius: 27px;
          margin: 0 10px 0 0;
        }

        .entrance_btn {
          font-size: 19px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: center;
          color: #ffffff;
          line-height: 50px;
          width: 133px;
          height: 50px;
          background: #ff7272;
          border-radius: 9px;
          cursor: pointer;
        }
      }
    }

    .chat_title {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 0 0 46px;
      margin: 0 0 15px 0;

      span {
        display: inline-block;
        width: 3px;
        height: 12px;
        background: #000000;
        border-radius: 2px;
      }

      img {
        margin: 0 9px 0 16px;
        width: 28px;
        height: 24px;
      }

      div {
        font-size: 20px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #000000;
        line-height: 20px;
      }

    }

    .chat-tabs {
      background: #283748;
      border-radius: 10px;
      padding: 0 46px;
      margin: 5px 0 15px 0;

      /deep/ .el-tabs__header {
        margin: 0;

        .el-tabs__active-bar {
          background-color: #f4a815;
        }

        .el-tabs__item {
          height: 50px;
          line-height: 50px;
          font-size: 18px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: CENTER;
          color: #ffffff;

          &.is-active {
            font-size: 20px;
            font-weight: 500;
          }
        }
      }

      /deep/ .el-tabs__nav-wrap::after {
        background-color: #283748;
        bottom: 1px,
      }
    }

    .chat_list {
      display: flex;
      flex-direction: column;

      .chat_list_item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 15px 15px 15px 45px;
        border-bottom: 1px solid #E2E2E2;

        .item_img {
          width: 50px;
          height: 50px;
          margin: 0 10px 0 0;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 5px;
          }
        }

        .item_content {
          width: 300px;

          .IC_name {
            display: flex;
            flex-direction: row;
            align-items: center;

            .IC_tag {
              width: 51px;
              height: 22px;
              line-height: 22px;
              border-radius: 5px;
              box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
              font-size: 12px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              text-align: center;
              color: #ffffff;
            }

            .IC_tag_1 {
              background: #FFBD80;
            }

            .IC_tag_2 {
              background: #11CB5B;
            }

            .IC_tag_3 {
              background: #80A8FF;
            }

            #IC_tagName {
              max-width: 180px;
              font-size: 16px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              color: #282828;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin: 0 6px;
            }

            .item_num {
              min-width: 58px;
              font-size: 16px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 400;
              color: #5B5B5B;
              line-height: 22px;
            }
          }

          .IC_msg {
            margin-top: 6px;
            display: flex;
            align-items: center;
            width: 243px;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Light;
            font-weight: 300;
            color: #a5acb4;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }


        .item_state {
          margin: 0 54px;

          /deep/ .el-tag {
            width: 45px;
            text-align: center;
          }

          /deep/ .el-tag--info {
            background: #F0F0F0;
            border-radius: 5px;
            border: none;
            font-size: 14px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 400;
            color: #BFBFBF;
            line-height: 27px;
          }

          /deep/ .el-tag--warning {
            min-width: 45px;
            text-align: center;
            background: #FFF2D9;
            border-radius: 5px;
            border: none;
            font-size: 14px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 400;
            color: #FCAD13;
            line-height: 27px;
          }
        }

        .item_date {
          text-align: center;
          flex: 1;
          font-size: 16px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 300;
          color: #8F8F8F;
          line-height: 22px;
          width: 308px;
        }

        .item_btn {
          width: 110px;
          height: 40px;
          background: #ffdb51;
          border-radius: 20px;
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Light;
          font-weight: 300;
          text-align: CENTER;
          color: #000000;
          line-height: 40px;
          cursor: pointer;
        }
      }

      .pages {
        height: 94px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .no_data {
      height: 696px;
      display: flex;
      justify-content: center;
      align-items: center;

      #noData {
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }

}
</style>
